<template>
    <v-container style="max-width: 800px; position: relative;">
        <LocaleChanger />
        <div>
             <v-img
                    id="OrgImage"
                    class="mx-auto"
                    max-width="300"
                    :src="logoUrl + this.$store.state.organization.s3 + '/Logo'" />
            <h1 class="font-weight-medium">{{ $t('tarkastele.title')}} {{this.report.organizationName}}</h1>
            <v-row class="mt-8">
                    <v-col class="contact-info-box">
                        <h2>{{ $t('confirmation.contactInformation')}}</h2>
                        <div class="mb-4" v-if="!report.reporter">
                            {{ $t('confirmation.anonymous')}}
                        </div>
                        <div class="mb-4" v-else>
                            <h3>{{ $t('contactInfo.name')}}</h3>
                            <p>{{report.reporter.name}}</p>
                            <h3>{{ $t('contactInfo.email')}}</h3>
                            <p>{{report.reporter.email}}</p>
                            <h3>{{ $t('contactInfo.phone')}}</h3>
                            <p>{{report.reporter.phone}}</p>
                        </div>
                        <div v-if="!report.notificationEmail">
                            <h2>{{ $t('confirmation.noEmailNotifications')}}</h2>
                        </div>
                        <div v-else>
                            <h2>{{ $t('confirmation.emailNotifications')}}</h2>
                            <p>{{report.notificationEmail}}</p>
                            <div class="d-flex" style="margin-top: 5px; align-items: center;">
                            <v-img src="/img/icons/info-circle.svg" height="1rem" width="1rem" style="flex-grow: 0; margin-right: 5px" />
                            <p><strong>{{ $t('confirmation.emailNotShared')}} {{this.$store.state.organization.name}} -{{ $t('confirmation.toOrganization')}}</strong></p>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            <v-row class="mt-8">
                    <v-col>
                        <h2>{{ $t('reportForm.subject')}}</h2>
                        <p>{{report.incidentTitle}}</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <h2>{{ $t('reportForm.time')}}</h2>
                        <p>{{report.timeOfIncident}}</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <h2>{{ $t('reportForm.place')}}</h2>
                        <p>{{report.placeOfIncident}}</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <h2>{{ $t('reportForm.details')}}</h2>
                        <p>{{report.content}}</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <h2>{{ $t('contactInfo.addedFiles')}}</h2>
                        <div v-if="report.attachments !== undefined && report.attachments.length>0">
                            <v-list-item-group color="primary">
                                    <v-list-item v-for="(item, index) in report.attachments" :key="index">
                                        <a>{{ item.name }}</a>
                                    </v-list-item>
                            </v-list-item-group>
                        </div>
                        <div v-else>
                            <p>{{ $t('tarkastele.noAttachment')}}</p>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                      <v-card class="comment-box">
                            <v-card-title>{{ $t('tarkastele.messages')}}</v-card-title>
                            <v-row v-if="report.externalComments !== undefined && report.externalComments.length>0">
                                <v-list-item v-for="(item, index) in report.externalComments" :key="index">
                                    <v-card-text>{{item.commenterName}}: {{item.comment}}</v-card-text>
                                </v-list-item>
                            </v-row>
                            <v-row v-else>
                                <v-card-text>{{ $t('tarkastele.noMessages')}}</v-card-text>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field v-model="comment" :label="$t('tarkastele.writeMessage')" outlined/>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn 
                                        primary
                                        depressed
                                        rounded
                                        color="primary"
                                        @click="sendComment">
                                        {{ $t('tarkastele.sendMessage')}}
                                    </v-btn>
                                </v-col>
                            </v-row>
                      </v-card>
                    </v-col>
                </v-row>
        </div>
    </v-container>
</template>

<style scoped>
.contact-info-box {
    border: 1px solid #9FA0AB;
    background-color: #F6F6F7;
    padding: 25px;
}

.comment-box {
    border: 1px solid #9FA0AB;
    background-color: #F6F6F7;
    padding: 10px;
}

p {
    margin: 0;
}

h2 {
    font-size: 1.2rem;
    font-weight: 500;
}

.contact-info-box h3 {
    font-size: 1rem;
    font-weight: 500;
    margin-top: 10px;
}

</style>

<script>
import LocaleChanger from '../components/LocaleChanger.vue'

export default {
    data() {
        return {
            report: {},
            comment: "",
            logoUrl: process.env.VUE_APP_VIHJAA_LOGOS
        }
    },
    components: {
        LocaleChanger
    },
    methods: {
        sendComment: function() {
            console.log(this.report)
            var commentDTO = {
                commenterName: this.$t('tarkastele.anonymous'),
                comment: this.comment,
                timestamp: new Date().toISOString(),
                origin: "ORIGINAL_REPORTER",
                reportId: this.report.uuid,
                orgId: this.report.organizationId
            }
            this.comment = ""
            var url = process.env.VUE_APP_VIHJAA_ENDPOINT + "/incident/comment"
            /* eslint-disable no-unused-vars */
            this.axios.post(url, commentDTO).then(response => {
                this.report.externalComments.push(commentDTO)
                this.$store.commit("setSnack", {
                    text: this.$i18n.locale == 'fi' ? "Viesti lähetetty" : "Message sent",
                    message: "",
                    color: "success",
                    });
                }).catch(e => {
                    console.log(e)
                    this.$store.commit("setSnack", {
                        text: this.$i18n.locale == 'fi' ? "Viestin lähetyksessä virhe" : "Error sending message",
                        message: "",
                        color: "error",
                    });
                    })
        }
    },
    mounted() {
        this.report = this.$store.state.report.reportResponse
    }
}
</script>
